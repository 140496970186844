<template>
  <div class="text-center">
    <v-dialog v-model="dialog" :persistent="isLoading" width="1000">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on">
          Export
          <v-icon right dark> mdi-microsoft-excel </v-icon>
        </v-btn>
      </template>

      <v-card :loading="isLoading">
        <v-card-title> Export Registrants Data </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="6">
              <div class="text-overline">Year Registered</div>
              <v-autocomplete v-model="form.selectedYears" :items="years" placeholder="Select Years" outlined dense chips small-chips multiple deletable-chips></v-autocomplete>
            </v-col>

            <v-col cols="3">
              <div class="text-overline">RSVP</div>
              <v-autocomplete
                v-model="filter.selectedAttendanceConfirmation"
                :items="confirmations"
                item-text="name"
                item-value="value"
                placeholder="Yes, No, or Pending"
                outlined
                dense
                clearable
              ></v-autocomplete>
            </v-col>

            <v-col cols="3">
              <div class="text-overline">Account Status</div>
              <v-autocomplete
                v-model="form.selectedAccountStatus"
                :items="accountStatuses"
                item-text="name"
                item-value="value"
                placeholder="Active or Blocked"
                outlined
                dense
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <div class="text-overline">Application Status</div>
              <v-autocomplete
                v-model="form.selectedApplicationVerificationStatus"
                :items="applicationVerificationStatuses"
                placeholder="Pending, Approved, or Declined"
                outlined
                dense
                clearable
              ></v-autocomplete>
            </v-col>

            <v-col cols="3">
              <div class="text-overline">Email Status</div>
              <v-autocomplete
                v-model="form.selectedEmailStatus"
                :items="emailStatuses"
                placeholder="Unverified, or Verified"
                item-text="name"
                item-value="value"
                outlined
                dense
                clearable
              ></v-autocomplete>
            </v-col>

            <v-col cols="3">
              <div class="text-overline">Previous Attendee</div>
              <v-autocomplete
                v-model="form.selectedpreviousAttendanceStatus"
                :items="previousAttendanceStatuses"
                item-text="name"
                item-value="value"
                placeholder="Yes or No"
                outlined
                dense
                clearable
              ></v-autocomplete>
            </v-col>

            <v-col cols="3">
              <div class="text-overline">Sidetracks</div>
              <v-autocomplete
                v-model="filter.selectedSideTracks"
                :items="sidetracks"
                placeholder="Hands On Lab, Capture the Flag, Get It Girls"
                outlined
                dense
                chips
                small-chips
                multiple
                deletable-chips
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-row class="mt-3">
            <v-col cols="4">
              <div class="text-overline">Role</div>
              <v-autocomplete v-model="form.selectedRole" :items="roles" placeholder="Select registrant's role" outlined dense clearable></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <div class="text-overline">Industry</div>
              <v-autocomplete v-model="form.selectedIndustry" :items="industries" placeholder="Select registrant's industry" outlined dense clearable></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <div class="text-overline">Function</div>
              <v-autocomplete v-model="form.selectedFunction" :items="functions" placeholder="Select registrant's function" outlined dense clearable></v-autocomplete>
            </v-col>
          </v-row>

          <div class="text-overline">Function Role</div>
          <v-autocomplete v-model="form.selectedfunctionRole" :items="functionRoles" placeholder="Select registrant's function role" outlined dense clearable></v-autocomplete>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn outlined :disabled="isLoading" @click="dialog = false">Cancel</v-btn>
          <v-btn color="primary" :loading="isLoading" @click="submitForm()">Export</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { writeFileXLSX, utils } from 'xlsx';

import roleFilter from '@/shared/constants/filters/roleFilter';
import industryFilter from '@/shared/constants/filters/industryFilter';
import functionFilter from '@/shared/constants/filters/functionFilter';
import functionRoleFilter from '@/shared/constants/filters/functionRoleFilter';
import boolFilter from '@/shared/constants/filters/boolFilter';
import { AM_SESSION, PM_SESSION, PLENARY } from '@/shared/constants/filters/sessions';
import { HANDS_ON_LAB, GET_IT_GIRL, CAPTURE_THE_FLAG, VIC_ONE } from '@/shared/constants/filters/invitations';

import { SET_RESPONSE_DIALOG } from '@/shared/mutations/type';

import { getExportedRegistrants } from '@/services/modules/RegistrantService';

export default {
  name: 'ExportDialog',
  data() {
    return {
      dialog: false,
      isLoading: false,
      years: [2024, 2023, 2022, 2021, 2020, 2019, 2018],
      delayTimer: null,
      accountStatuses: ['Active', 'Blocked'],
      applicationVerificationStatuses: ['No Application', 'Pending', 'Approved', 'Declined'],
      emailStatuses: [
        { name: 'Unverified', value: false },
        { name: 'Verified', value: true },
      ],
      attendingStatuses: boolFilter,
      confirmedAttendanceStatuses: boolFilter,
      previousAttendanceStatuses: boolFilter,
      sessions: [AM_SESSION, PM_SESSION, PLENARY],
      sidetracks: [HANDS_ON_LAB, CAPTURE_THE_FLAG, GET_IT_GIRL, VIC_ONE],
      confirmations: ['Yes', 'No', 'Pending'],
      roles: roleFilter,
      industries: industryFilter,
      functions: functionFilter,
      functionRoles: functionRoleFilter,

      form: {
        selectedYears: [2024, 2023, 2022, 2021, 2020, 2019, 2018],
        selectedAccountStatus: 'Active',
        selectedApplicationVerificationStatus: 'Pending',
        selectedEmailStatus: null,
        selectedpreviousAttendanceStatus: null,
        selectedSideTracks: [],
        selectedAttendanceConfirmation: null,
        selectedRole: null,
        selectedIndustry: null,
        selectedFunction: null,
        selectedfunctionRole: null,
      },
    };
  },
  computed: {
    ...mapState(['filter']),
  },
  watch: {
    dialog: {
      handler() {
        this.form = { ...this.filter };
      },
    },
  },

  methods: {
    generateUniqueFileName(extension) {
      const timestamp = new Date().toISOString().replace(/[-T:.]/g, '');
      return `registrants_export_${timestamp}.${extension}`;
    },
    generateExcelFile(data) {
      const worksheet = utils.json_to_sheet(data);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, 'Registrants');

      utils.sheet_add_aoa(
        worksheet,
        [
          [
            'IsBlocked',
            'Account Created',
            'User Id',
            'First Name',
            'Last Name',
            'Email',
            'Email Verified',
            'Phone Number',
            'Company',
            'Previous Attendee',
            'Profile Verification Status',
            'Application Status',
            'Role',
            'Industry',
            'Function',
            'Function Role',
            'HOL Attendee',
            'CTF Attendee',
            'GIG Attendee',
            'VicOne Attendee',
            'Attendance Confirmation',
          ],
        ],
        {
          origin: 'A1',
        },
      );

      worksheet['!cols'] = [
        { wch: 10 }, // Blocked
        { wch: 40 }, // Date
        { wch: 40 }, // User Id
        { wch: 15 }, // First Name
        { wch: 15 }, // Last Name
        { wch: 40 }, // Email
        { wch: 10 }, // Verified
        { wch: 20 }, // Phone No.
        { wch: 20 }, // Company
        { wch: 15 }, // Prev Attendee
        { wch: 15 }, // Profile Status
        { wch: 15 }, // Application Status
        { wch: 20 }, // Role
        { wch: 20 }, // Industry
        { wch: 20 }, // Function
        { wch: 50 }, // Function Role
        { wch: 10 }, // HOL
        { wch: 10 }, // CTF
        { wch: 10 }, // Verified
        { wch: 10 }, // VicOne
        { wch: 10 }, // Attendance Confirmation
      ];

      writeFileXLSX(workbook, this.generateUniqueFileName('xlsx'), {
        compression: true,
      });
    },
    rearrangeObject(obj) {
      const keyOrder = [
        'isRejected',
        'dateCreated',
        'userId',
        'firstName',
        'lastName',
        'email',
        'emailConfirmed',
        'phoneNumber',
        'company',
        'isLastYearAttendee',
        'profileVerificationStatus',
        'applicationStatus',
        'role',
        'industry',
        'function',
        'roleFunction',
        'isHolRegistrant',
        'isCtfRegistrant',
        'isGigRegistrant',
        'isVicOneRegistrant',
        'attendanceConfirmation',
      ];

      const rearrangedObj = {};
      keyOrder.forEach((key) => {
        if (key in obj) {
          rearrangedObj[key] = obj[key];
        }
      });
      return rearrangedObj;
    },
    async submitForm() {
      this.isLoading = true;
      const response = await getExportedRegistrants(this.form);

      if (!response) return;
      const arrangedResponse = response.map((obj) => this.rearrangeObject(obj));
      this.generateExcelFile(arrangedResponse);

      this.$store.commit(`${SET_RESPONSE_DIALOG}`, {
        visible: true,
        isSuccess: true,
        message: 'Success',
        subMessage: null,
        errors: [],
      });

      this.isLoading = false;
    },
  },
};
</script>

<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" v-on="on" @click="dialog = true">
          <v-list-item-title class="black--text"> <v-icon color="black" class="mr-2">mdi-restore</v-icon><span>Unblock</span> </v-list-item-title>
        </v-list-item>
      </template>
      <span>Unblock user's account</span>
    </v-tooltip>

    <v-dialog v-model="dialog" :persistent="isLoading" width="500">
      <v-card :loading="isLoading">
        <v-card-title>Unblock user's account?</v-card-title>
        <v-card-text>
          <p>Unblocking this user will allow them to restore their current application status, if applicable, and proceed with logging in if approved.</p>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn outlined :disabled="isLoading" @click="dialog = false">Cancel</v-btn>
          <v-btn color="primary" :loading="isLoading" @click="submitForm()">Unblock</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { SET_RESPONSE_DIALOG } from '@/shared/mutations/type';

export default {
  name: 'UnblockDialog',
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
    };
  },
  methods: {
    ...mapActions(['unblockUserAccountAction']),

    async submitForm() {
      this.isLoading = true;

      const response = await this.unblockUserAccountAction(this.userId);

      if (response) {
        this.$store.commit(`${SET_RESPONSE_DIALOG}`, {
          visible: true,
          isSuccess: true,
          message: 'Success',
          subMessage: 'The user has been successfully unblocked.',
          errors: [],
        });

        this.dialog = false;
      } else this.dialog = true;

      this.isLoading = false;
    },
  },
};
</script>

<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" v-on="on" @click="dialog = true">
          <v-list-item-title class="warning--text" v-bind="attrs" v-on="on" @click="dialog = true">
            <v-icon color="warning" class="mr-2">mdi-sticker-remove-outline</v-icon><span>Decline</span>
          </v-list-item-title>
        </v-list-item>
      </template>
      <span>Decline user's application and verification status</span>
    </v-tooltip>

    <v-dialog v-model="dialog" :persistent="isLoading" width="500">
      <v-card :loading="isLoading">
        <v-card-title>Decline user's attendance?</v-card-title>
        <v-card-text>
          <p>An email will be sent to the user that they are declined and will not be able to join this year's Decode.</p>
          <p>Please provide the reason why the user will be rejected. This would not be sent to the user and will only be used for future reference.</p>

          <v-form ref="reasonForRejectionForm" v-model="rejectionFormIsValid">
            <v-select v-model="reasonForRejection" :items="rejectionReasons" :rules="reasonForRejectionRules" label="Reason for rejection" dense outlined></v-select>
            <v-textarea v-if="reasonForRejection === 'Others'" v-model="otherReason" :rules="otherReasonForRejectionRules" outlined name="input-7-4" label="Notes"></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn outlined :disabled="isLoading" @click="dialog = false">Cancel</v-btn>
          <v-btn color="primary" :loading="isLoading" @click="submitForm()">Decline</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { SET_RESPONSE_DIALOG } from '@/shared/mutations/type';

export default {
  name: 'DeclineDialog',
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      rejectionReasons: ['User is a student', 'Duplicate record', 'Competitor', 'Others'],
      rejectionFormIsValid: true,
      reasonForRejectionRules: [(v) => !!v || 'This field is required'],
      otherReasonForRejectionRules: [(v) => !!v || 'This field is required'],
      reasonForRejection: '',
      otherReason: '',
    };
  },
  methods: {
    ...mapActions(['declineUserAttendanceAction']),

    async submitForm() {
      if (!this.$refs.reasonForRejectionForm.validate()) return;

      this.isLoading = true;

      const response = await this.declineUserAttendanceAction({
        id: this.userId,
        reason: this.reasonForRejection,
        remarks: this.otherReason,
      });

      if (response) {
        this.$store.commit(`${SET_RESPONSE_DIALOG}`, {
          visible: true,
          isSuccess: true,
          message: 'Success',
          subMessage: 'Rejection email has been sent.',
          errors: [],
        });

        this.dialog = false;
      } else this.dialog = true;

      this.isLoading = false;
    },
  },
};
</script>

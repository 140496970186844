export default [
  'Administrative/Middle Management',
  'Business Professional',
  'Executive Management',
  'Faculty/Educator/Training Professional',
  'Information Security',
  'IT Operations',
  'IT Specialist',
  'Software Development',
  'Student',
  'Others',
];

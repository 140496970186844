<template>
  <v-container>
    <user-dialog :dialog="userDialog.visibility" :user="userDialog.user" :on-close="closeUserDialog" />
    <bulk-confirmation-dialog :dialog="bulkConfirmationDialog" @closeBulkConfirmationDialog="bulkConfirmationDialog = false" @getRegistrantList="getRegistrantList()" />
    <data-filter />

    <v-card class="mt-10">
      <div class="pa-5 d-flex align-center">
        <!-- <download-excel
          class="d-inline-flex"
          :data="registrants"
          :fields="exportHeaders"
          :before-generate="startExport"
          :before-finish="finishExport"
          worksheet="Decode Registrants"
          :name="uniqueFilename"
        >
          <v-btn class="text-overline mr-2" color="blue darken--2 white--text" :loading="exportLoading"> <v-icon left dark> mdi-microsoft-excel </v-icon> Export Excel </v-btn>
        </download-excel> -->

        <v-btn class="text-overline mr-2" color="red darken--2 white--text" @click="bulkConfirmationDialog = true">
          <v-icon left>mdi-email</v-icon>
          Send Bulk Confirmation
        </v-btn>

        <export-dialog class="mr-2" />

        <create-user-dialog />

        <v-spacer></v-spacer>
        <v-text-field v-model="tableSearch" append-icon="mdi-magnify" label="Search registrant" clearable single-line hide-details></v-text-field>

        <v-btn class="ml-2" color="primary" @click="getRegistrantList()">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </div>

      <v-data-table
        :loading="isLoading"
        :headers="tableHeaders"
        :items-per-page="50"
        :items="registrants"
        :options.sync="options"
        loading-text="Loading... Please wait"
        item-key="userId"
        hide-default-footer
      >
        <template v-slot:item.name="{ item }">
          <a class="text-decoration-none" @click="openUserDialog(item)">{{ item.firstName }} {{ item.lastName }}</a>
        </template>

        <template v-slot:item.dateCreated="{ item }">
          {{ item.dateCreated | readableDate }}
        </template>

        <template v-slot:item.lastNote="{ item }">
          <div v-if="item.lastNote != null" class="font-italic font-weight-bold">{{ item.lastNote }}</div>
          <div v-else class="font-weight-light">N/A</div>
        </template>

        <template v-slot:item.status="{ item }">
          <status-item
            :email-confirmed="item.emailConfirmed"
            :is-last-year-attendee="item.isLastYearAttendee"
            :is-rejected="item.isRejected"
            :is-confirmed="item.isConfirmed"
            :is-pending="item.isPending"
            :is-attending="item.isAttending"
            :profile-verification-status="item.profileVerificationStatus"
            :application-status="item.applicationStatus"
            :is-attending-am-session="item.isAttendingAMSession"
            :is-attending-pm-session="item.isAttendingPMSession"
            :is-attending-plenary="item.isAttendingPlenary"
            :is-final-confirmed="item.isFinalConfirmed"
            :is-hol-registrant="item.isHolRegistrant"
            :is-gig-registrant="item.isGigRegistrant"
            :is-ctf-registrant="item.isCtfRegistrant"
            :is-vic-one-registrant="item.isVicOneRegistrant"
            :attendance-confirmation="item.attendanceConfirmation"
          />
        </template>

        <template v-slot:item.email="{ item }">
          <v-tooltip bottom :color="item.emailConfirmed ? 'success' : 'error'">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-badge bordered :color="item.emailConfirmed ? 'success' : 'error'" :icon="item.emailConfirmed ? 'mdi-check' : 'mdi-close'">
                  <span>{{ item.email }}</span>
                </v-badge>
              </span>
            </template>
            <span>{{ item.emailConfirmed ? 'Verified email' : 'Unverified email' }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.action="{ item }">
          <v-menu :close-on-content-click="false" class="my-2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" outlined v-bind="attrs" v-on="on">Actions <v-icon class="mr-2" small>mdi-menu-down</v-icon></v-btn>
            </template>
            <v-list dense>
              <v-list-item-group>
                <note-dialog class="my-2" :user-id="item.userId" />
                <div v-if="item.emailConfirmed && item.applicationStatus === 'Pending' && !item.isRejected">
                  <approve-dialog class="my-2" :user-id="item.userId" />
                  <decline-dialog class="mb-2" :user-id="item.userId" />
                </div>
                <div v-else-if="!item.emailConfirmed && item.applicationStatus === 'Pending' && !item.isRejected">
                  <decline-dialog class="my-2" :user-id="item.userId" />
                </div>
                <div v-if="!item.isRejected"><block-dialog class="my-2" :user-id="item.userId" /></div>
                <div v-else-if="item.isRejected"><unblock-dialog class="my-2" :user-id="item.userId" /></div>
              </v-list-item-group>
              <delete-dialog class="my-2" :user-id="item.userId" />
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-progress-linear v-if="isLoading" class="mt-4" indeterminate></v-progress-linear>
      <div class="d-flex justify-end align-center">
        <div class="d-flex align-center mr-4">
          <span class="text-caption mr-4">Rows per page: </span>
          <v-select v-model="itemsPerPage" :full-width="false" :items="[10, 20, 30, 40, 50]" class="inline select-box" style="width: 50px"></v-select>
          <span class="text-caption mx-4">Page: {{ page }} - {{ metaData.totalPages }}</span>
        </div>
        <v-pagination v-model="page" :length="metaData.totalPages" :total-visible="5" :disabled="isLoading" circle></v-pagination>
      </div>
      <p class="text-caption text-right mx-4 pb-5">Total Items: {{ metaData.totalItems }}</p>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { format } from 'date-fns';

import BulkConfirmationDialog from '@/components/BulkConfirmationDialog';
import ApproveDialog from '@/components/Registrants/ApproveDialog';
import DeclineDialog from '@/components/Registrants/DeclineDialog';
import BlockDialog from '@/components/Registrants/BlockDialog';
import UnblockDialog from '@/components/Registrants/UnblockDialog';
import DeleteDialog from '@/components/Registrants/DeleteDialog';
import NoteDialog from '@/components/Registrants/NoteDialog';
import ExportDialog from '@/components/Registrants/ExportDialog';
import CreateUserDialog from '@/components/Registrants/CreateUserDialog';

import UserDialog from '@/components/Registrants/UserDialog';
import DataFilter from '@/components/Registrants/DataFilter';
import StatusItem from '@/components/Registrants/StatusItem';

import registrantHeaders from '@/shared/constants/registrantHeaders';
import exportHeaders from '@/shared/constants/exportHeaders';

import { SET_PAGINATION, SET_SEARCH, SET_SORTING } from '@/shared/mutations/type';

export default {
  name: 'RegistrantList',
  components: {
    BulkConfirmationDialog,
    ApproveDialog,
    DeclineDialog,
    BlockDialog,
    UnblockDialog,
    DeleteDialog,
    NoteDialog,
    UserDialog,
    DataFilter,
    StatusItem,
    ExportDialog,
    CreateUserDialog,
  },
  filters: {
    readableDate: (value) => {
      if (!value) return '';
      return format(new Date(value), 'MMMM d, y');
    },
  },
  data() {
    return {
      exportLoading: false,
      delayTimer: null,
      userDialog: {
        user: null,
        visibility: false,
      },
      tableSearch: '',
      options: {},
      tableHeaders: registrantHeaders,
      exportHeaders,
      bulkConfirmationDialog: false,
      page: 1,
      itemsPerPage: 10,
    };
  },
  computed: {
    ...mapState(['isLoading', 'metaData', 'registrants', 'costCenterOfficer']),

    uniqueFilename: () => {
      const now = new Date();
      const datePart = now.toISOString().slice(0, 10).replace(/-/g, '');
      const timePart = now.toISOString().slice(11, 19).replace(/:/g, '');
      return `decode-registrants-${datePart}-${timePart}.xls`;
    },
  },
  watch: {
    page(newValue) {
      this.$store.commit(SET_PAGINATION, {
        page: newValue,
        pageSize: this.itemsPerPage,
      });
      this.getRegistrantList();
    },
    itemsPerPage(newValue) {
      this.$store.commit(SET_PAGINATION, {
        page: this.page,
        pageSize: newValue,
      });
      this.getRegistrantList();
    },
    tableSearch(newValue) {
      clearTimeout(this.delayTimer);

      this.$store.commit(SET_SEARCH, newValue ? newValue.toLowerCase() : '');

      this.delayTimer = setTimeout(async () => {
        this.page = 1;
        this.$store.commit(SET_PAGINATION, {
          page: 1,
          pageSize: this.itemsPerPage,
        });
        await this.getRegistrantList();
      }, 2000);
    },
    options({ sortBy, sortDesc }) {
      if (sortBy[0]) {
        const sortColumn = sortBy[0] === 'name' ? 'firstName' : sortBy[0];
        this.$store.commit(SET_SORTING, {
          sortBy: sortColumn,
          sortDesc: sortDesc[0],
        });

        this.getRegistrantList();
      }
    },
  },
  methods: {
    ...mapActions(['getRegistrantsByFilterAction']),

    async getRegistrantList() {
      await this.getRegistrantsByFilterAction();
    },
    startExport() {
      this.exportLoading = true;
    },
    finishExport() {
      this.exportLoading = false;
    },
    openUserDialog(user) {
      this.userDialog = {
        user,
        visibility: true,
      };
    },
    closeUserDialog() {
      this.userDialog.visibility = false;
    },
  },
};
</script>

<style scoped>
.inline .select-box {
  max-width: 30px !important;
}
</style>

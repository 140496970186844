<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" v-on="on" @click="dialog = true">
          <v-list-item-title class="success--text"> <v-icon color="success" class="mr-2">mdi-sticker-check-outline</v-icon><span>Approve</span></v-list-item-title>
        </v-list-item>
      </template>
      <span>Approve user's application and verification status</span>
    </v-tooltip>

    <v-dialog v-model="dialog" :persistent="isLoading" width="500">
      <v-card :loading="isLoading">
        <v-card-title> Confirm user attendance? </v-card-title>
        <v-card-text> An email will be sent to the user notifying their attendance for this year's Decode is confirmed. </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn outlined :disabled="isLoading" @click="dialog = false">Cancel</v-btn>
          <v-btn color="primary" :loading="isLoading" @click="submitForm()">Approve</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { SET_RESPONSE_DIALOG } from '@/shared/mutations/type';

export default {
  name: 'ApproveDialog',
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
    };
  },
  methods: {
    ...mapActions(['confirmUserAttendanceAction']),

    async submitForm() {
      this.isLoading = true;
      const response = await this.confirmUserAttendanceAction(this.userId);

      if (response) {
        this.$store.commit(`${SET_RESPONSE_DIALOG}`, {
          visible: true,
          isSuccess: true,
          message: 'Success',
          subMessage: 'Attendance confirmation email has been sent.',
          errors: [],
        });

        this.dialog = false;
      } else this.dialog = true;

      this.isLoading = false;
    },
  },
};
</script>

<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" v-on="on" @click="dialog = true">
          <v-list-item-title class="red--text"> <v-icon color="red" class="mr-2">mdi-trash-can</v-icon><span>Delete</span></v-list-item-title>
        </v-list-item>
      </template>
      <span>Delete user's account</span>
    </v-tooltip>

    <v-dialog v-model="dialog" :persistent="isLoading" width="500">
      <v-card :loading="isLoading">
        <v-card-title> Are you sure you want to delete this account? </v-card-title>
        <v-card-text>
          <p>Please ensure that you are certain about this action, as once submitted, it cannot be undone.</p>

          <v-form ref="remarkFormIsValid" v-model="remarkFormIsValid">
            <v-textarea v-model="content" :rules="contentRules" outlined name="input-7-4" label="Remarks"></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn outlined :disabled="isLoading" @click="dialog = false">Cancel</v-btn>
          <v-btn color="primary" :loading="isLoading" @click="submitForm()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { SET_RESPONSE_DIALOG } from '@/shared/mutations/type';

export default {
  name: 'DeleteDialog',
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      remarkFormIsValid: true,
      contentRules: [(v) => !!v || 'This field is required', (v) => v.length < 501 || 'Maximum of 500 characters'],
      content: '',
    };
  },
  methods: {
    ...mapActions(['deleteUserAccountAction']),
    async submitForm() {
      if (!this.$refs.remarkFormIsValid.validate()) return;
      this.isLoading = true;
      const response = await this.deleteUserAccountAction({ id: this.userId, remarks: this.content });

      if (response) {
        this.$store.commit(`${SET_RESPONSE_DIALOG}`, {
          visible: true,
          isSuccess: true,
          message: 'Success',
          subMessage: 'Account deletion successful! The account has been permanently deleted.',
          errors: [],
        });

        this.content = '';
        this.dialog = false;
      } else this.dialog = true;

      this.isLoading = false;
    },
  },
};
</script>

export default [
  'Banking, Finance, Insurance, and Holdings',
  'Business and Professional Services',
  'Education',
  'Energy',
  'Entertainment, Leisure, and Travel',
  'Government',
  'Healthcare',
  'Hotel and Restaurant',
  'Manufacturing and Industrial',
  'Media and Publishing',
  'Military and Defense',
  'Real Estate and Property Development',
  'Religious Institution',
  'Retail, Trading, and Consumer Goods',
  'Technology and IT Services',
  'Telecommunications',
  'Transport and Shipping',
  'Others',
];

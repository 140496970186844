<template>
  <v-card class="mb-2 pa-4" elevation="1" outlined>
    <v-btn text @click="expand = !expand"> <v-icon left red darken--2> mdi-filter </v-icon>Filters</v-btn>

    <v-expand-transition>
      <v-sheet v-show="expand" class="mt-10">
        <v-row>
          <v-col cols="6">
            <div class="text-overline">Year Registered</div>
            <v-autocomplete v-model="filter.selectedYears" :items="years" placeholder="Select Years" outlined dense chips small-chips multiple deletable-chips></v-autocomplete>
          </v-col>

          <v-col cols="3">
            <div class="text-overline">RSVP</div>
            <v-autocomplete
              v-model="filter.selectedAttendanceConfirmation"
              :items="confirmations"
              item-text="name"
              item-value="value"
              placeholder="Yes, No, or Pending"
              outlined
              dense
              clearable
            ></v-autocomplete>
          </v-col>

          <v-col cols="3">
            <div class="text-overline">Account Status</div>
            <v-autocomplete
              v-model="filter.selectedAccountStatus"
              :items="accountStatuses"
              item-text="name"
              item-value="value"
              placeholder="Active or Blocked"
              outlined
              dense
              clearable
            ></v-autocomplete>
          </v-col>

          <!-- <v-col cols="3">
            <div class="text-overline">Attendee</div>
            <v-autocomplete v-model="filter.selectedAttendingStatus" :items="attendingStatuses" item-text="name" item-value="value" placeholder="Yes or No" outlined dense clearable></v-autocomplete
          ></v-col> -->
        </v-row>

        <v-row>
          <!-- <v-col cols="4">
            <div class="text-overline">Account Status</div>
            <v-autocomplete v-model="filter.selectedAccountStatus" :items="accountStatuses" placeholder="Pending, Confirmed, or Declined" outlined dense clearable></v-autocomplete>
          </v-col> -->

          <v-col cols="3">
            <div class="text-overline">Application Status</div>
            <v-autocomplete
              v-model="filter.selectedApplicationVerificationStatus"
              :items="applicationVerificationStatuses"
              placeholder="Pending, Approved, or Declined"
              outlined
              dense
              clearable
            ></v-autocomplete>
          </v-col>

          <v-col cols="3">
            <div class="text-overline">Email Status</div>
            <v-autocomplete
              v-model="filter.selectedEmailStatus"
              :items="emailStatuses"
              placeholder="Unverified, or Verified"
              item-text="name"
              item-value="value"
              outlined
              dense
              clearable
            ></v-autocomplete>
          </v-col>

          <v-col cols="3">
            <div class="text-overline">Previous Attendee</div>
            <v-autocomplete
              v-model="filter.selectedpreviousAttendanceStatus"
              :items="previousAttendanceStatuses"
              item-text="name"
              item-value="value"
              placeholder="Yes or No"
              outlined
              dense
              clearable
            ></v-autocomplete>
          </v-col>

          <v-col cols="3">
            <div class="text-overline">Sidetracks</div>
            <v-autocomplete
              v-model="filter.selectedSideTracks"
              :items="sidetracks"
              placeholder="Hands On Lab, Capture the Flag, Get It Girls"
              outlined
              dense
              chips
              small-chips
              multiple
              deletable-chips
            ></v-autocomplete>
          </v-col>

          <!-- <v-col cols="4">
            <div class="text-overline">Sessions</div>
            <v-autocomplete v-model="filter.selectedSessions" :items="sessions" label="AM, PM, or Plenary" outlined dense chips small-chips multiple deletable-chips></v-autocomplete>
          </v-col>

          <v-col cols="4">
            <div class="text-overline">Invitations</div>
            <v-autocomplete v-model="filter.selectedInvitations" :items="invitations" label="Hands on Lab, or Get It Girl" outlined dense chips small-chips multiple deletable-chips></v-autocomplete>
          </v-col> -->
        </v-row>

        <v-row>
          <!-- <v-col cols="4">
            <div class="text-overline">Attendee</div>
            <v-autocomplete v-model="filter.selectedAttendingStatus" :items="attendingStatuses" item-text="name" item-value="value" placeholder="Yes or No" outlined dense clearable></v-autocomplete>
          </v-col> -->

          <!-- <v-col cols="3">
            <div class="text-overline">Confirmed Attendance</div>
            <v-autocomplete
              v-model="filter.selectedConfirmedAttendanceStatus"
              :items="confirmedAttendanceStatuses"
              item-text="name"
              item-value="value"
              placeholder="Yes or No"
              outlined
              dense
              clearable
              disabled
            ></v-autocomplete>
          </v-col> -->

          <!-- <v-col cols="4">
            <div class="text-overline">Previous Attendee</div>
            <v-autocomplete
              v-model="filter.selectedpreviousAttendanceStatus"
              :items="previousAttendanceStatuses"
              item-text="name"
              item-value="value"
              placeholder="Yes or No"
              outlined
              dense
              clearable
            ></v-autocomplete>
          </v-col> -->
        </v-row>

        <v-divider></v-divider>

        <v-row class="mt-3">
          <v-col cols="4">
            <div class="text-overline">Role</div>
            <v-autocomplete v-model="filter.selectedRole" :items="roles" placeholder="Select registrant's role" outlined dense clearable></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <div class="text-overline">Industry</div>
            <v-autocomplete v-model="filter.selectedIndustry" :items="industries" placeholder="Select registrant's industry" outlined dense clearable></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <div class="text-overline">Function</div>
            <v-autocomplete v-model="filter.selectedFunction" :items="functions" placeholder="Select registrant's function" outlined dense clearable></v-autocomplete>
          </v-col>
        </v-row>

        <div class="text-overline">Function Role</div>
        <v-autocomplete v-model="filter.selectedfunctionRole" :items="functionRoles" placeholder="Select registrant's function role" outlined dense clearable></v-autocomplete>
      </v-sheet>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

import roleFilter from '@/shared/constants/filters/roleFilter';
import industryFilter from '@/shared/constants/filters/industryFilter';
import functionFilter from '@/shared/constants/filters/functionFilter';
import functionRoleFilter from '@/shared/constants/filters/functionRoleFilter';
import boolFilter from '@/shared/constants/filters/boolFilter';
import { AM_SESSION, PM_SESSION, PLENARY } from '@/shared/constants/filters/sessions';
import { HANDS_ON_LAB, GET_IT_GIRL, CAPTURE_THE_FLAG, VIC_ONE } from '@/shared/constants/filters/invitations';

import { SET_FILTER, SET_PAGINATION } from '@/shared/mutations/type';

export default {
  name: 'DataFilter',

  data() {
    return {
      expand: true,
      years: [2024, 2023, 2022, 2021, 2020, 2019, 2018],
      delayTimer: null,
      accountStatuses: ['Active', 'Blocked'],
      applicationVerificationStatuses: ['No Application', 'Pending', 'Approved', 'Declined'],
      emailStatuses: [
        { name: 'Unverified', value: false },
        { name: 'Verified', value: true },
      ],
      attendingStatuses: boolFilter,
      confirmedAttendanceStatuses: boolFilter,
      previousAttendanceStatuses: boolFilter,
      sessions: [AM_SESSION, PM_SESSION, PLENARY],
      sidetracks: [HANDS_ON_LAB, CAPTURE_THE_FLAG, GET_IT_GIRL, VIC_ONE],
      confirmations: ['Yes', 'No', 'Pending'],
      roles: roleFilter,
      industries: industryFilter,
      functions: functionFilter,
      functionRoles: functionRoleFilter,

      filter: {
        selectedYears: [2024, 2023, 2022, 2021, 2020, 2019, 2018],
        selectedAccountStatus: 'Active',
        selectedApplicationVerificationStatus: 'Pending',
        selectedEmailStatus: null,
        // selectedAttendingStatus: null,
        // selectedConfirmedAttendanceStatus: null,
        selectedpreviousAttendanceStatus: null,
        // selectedSessions: [],
        selectedSideTracks: [],
        selectedAttendanceConfirmation: null,
        selectedRole: null,
        selectedIndustry: null,
        selectedFunction: null,
        selectedfunctionRole: null,
      },
    };
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(async () => {
          localStorage.setItem('filter', JSON.stringify(this.filter));
          this.$store.commit(SET_FILTER, this.filter);
          this.$store.commit(SET_PAGINATION, {
            page: 1,
            pageSize: 10,
          });
          await this.getRegistrantsByFilterAction();
        }, 2000);
      },
      deep: true,
    },
  },
  async created() {
    const prevFilter = JSON.parse(localStorage.getItem('filter'));
    if (prevFilter) {
      this.filter = prevFilter;
    }
    this.$store.commit(SET_FILTER, this.filter);
    await this.getRegistrantsByFilterAction();
  },
  methods: {
    ...mapActions(['getRegistrantsByFilterAction']),
  },
};
</script>

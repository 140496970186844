<template>
  <div class="text-center">
    <v-dialog v-model="dialog" :persistent="isLoading" width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="success" v-bind="attrs" v-on="on">
          Create Users
          <v-icon right dark> mdi-account-multiple-plus </v-icon>
        </v-btn>
      </template>

      <v-card :loading="isLoading">
        <v-card-title class="mb-4"> Create Users </v-card-title>

        <v-card-text class="mb-10">
          <v-form ref="form">
            <v-row v-for="(user, index) in users" :key="index">
              <v-col cols="10">
                <v-chip color="primary" outlined label small> User # {{ index + 1 }} </v-chip>
              </v-col>

              <v-col v-if="users.length > 1" class="d-flex justify-end" cols="2">
                <v-btn small color="error" @click="removeUser(index)">
                  <v-icon left>mdi-delete-circle</v-icon>
                  Remove
                </v-btn>
              </v-col>

              <v-col class="py-0" sm="6" cols="12">
                <div class="text-overline">First Name <strong class="red--text text--darken-4">*</strong></div>
                <v-text-field
                  v-model="user.firstName"
                  :rules="[(v) => !!v || 'First name is required', (v) => (v && v.length > 0 && v.length <= 100) || 'First name should not be less than 0 and must not exceed 100 characters.']"
                  placeholder="Input First name"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col class="py-0" sm="6" cols="12">
                <div class="text-overline">Last Name <strong class="red--text text--darken-4">*</strong></div>
                <v-text-field
                  v-model="user.lastName"
                  :rules="[(v) => !!v || 'Last name is required', (v) => (v && v.length > 0 && v.length <= 100) || 'Last name should not be less than 0 and must not exceed 100 characters.']"
                  placeholder="Input Last name"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col class="py-0" sm="12" cols="12">
                <div class="text-overline">Email <strong class="red--text text--darken-4">*</strong></div>
                <v-text-field
                  v-model="user.email"
                  :rules="[(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid', (v) => v.length <= 300 || 'Email should must not exceed 100 characters.']"
                  placeholder="Input Email"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col class="py-0" sm="6" cols="12">
                <div class="text-overline">Company</div>
                <v-text-field v-model="user.company" :rules="[(v) => v.length <= 100 || 'Company should must not exceed 100 characters.']" placeholder="Input Last name" outlined dense></v-text-field>
              </v-col>

              <v-col class="py-0" sm="6" cols="12">
                <div class="text-overline">Phone Number</div>
                <v-text-field
                  v-model="user.phoneNumber"
                  :rules="[(v) => v === '' || v.startsWith('09') || 'Phone number should start with \'09\'.', (v) => v === '' || v.length === 11 || 'Phone number should contain 11 characters.']"
                  placeholder="Input Last name E.g 09123456789"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col class="my-2" cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-form>

          <div v-if="users.length < 5" class="mt-2 d-flex justify-end">
            <v-btn elevation="2" color="primary" small outlined @click="addUser()"> <v-icon left> mdi-plus </v-icon>Add</v-btn>
          </div>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn outlined :disabled="isLoading" @click="dialog = false">Cancel</v-btn>
          <v-btn color="primary" :loading="isLoading" @click="submitForm()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="resultDialog" max-width="500">
      <v-card class="response-success px-10 pt-10 pb-5">
        <v-img class="mx-auto" :width="100" :height="100" :src="require('@/assets/checked.png')"></v-img>

        <p class="text-center mt-5 text-h6">Request Success!</p>

        <p class="text-body-1 font-weight-normal">Here are the outcomes of your request:</p>

        <div v-if="result">
          <div v-if="result.users.length > 0">
            <v-chip color="success" outlined label small> New User(s) </v-chip>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th id="email" class="text-left">Email</th>
                    <th id="password" class="text-left">Password</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in result.users" :key="index">
                    <td>{{ item.email }}</td>
                    <td>{{ item.password }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

          <div v-if="result.failures.length > 0">
            <v-chip class="mt-4" color="error" outlined label small> Failure(s) </v-chip>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th id="email" class="text-left">Email</th>
                    <th id="message" class="text-left">Error</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in result.failures" :key="index">
                    <td>{{ item.user }}</td>
                    <td>{{ item.message }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>
        <v-card-actions class="justify-center mt-5">
          <v-btn color="blue" dark variant="flat" height="48" class="px-10" @click="resultDialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.response-success {
  border-top: 10px solid #32ba7c !important;
}
</style>

<script>
import { SET_CONFIRMATION_DIALOG, CLOSE_CONFIRMATION_DIALOG } from '@/shared/mutations/type';
import { createUsers } from '@/services/modules/RegistrantService';

export default {
  name: 'ExportDialog',
  data() {
    return {
      dialog: false,
      resultDialog: false,
      isLoading: false,
      users: [
        {
          firstName: '',
          lastName: '',
          email: '',
          company: '',
          phoneNumber: '',
        },
      ],
      result: null,
      desserts: [
        {
          name: 'Frozen Yogurt',
          calories: 159,
        },
        {
          name: 'Honeycomb',
          calories: 408,
        },
        {
          name: 'Donut',
          calories: 452,
        },
        {
          name: 'KitKat',
          calories: 518,
        },
      ],
    };
  },
  methods: {
    addUser() {
      const valid = this.$refs.form.validate();

      if (valid) {
        this.users.push({
          firstName: '',
          lastName: '',
          email: '',
          company: '',
          phoneNumber: '',
        });
      }
    },
    removeUser(index) {
      if (this.users.length > 1) this.users.splice(index, 1);
    },
    async createUsers() {
      const cleanedUsers = [];

      for (let i = 0; i < this.users.length; i += 1) {
        const obj = {};

        obj.firstName = this.users[i].firstName;
        obj.lastName = this.users[i].lastName;
        obj.email = this.users[i].email;

        if (this.users[i].company.length > 0) obj.company = this.users[i].company;
        if (this.users[i].phoneNumber.length > 0) obj.phoneNumber = this.users[i].phoneNumber.substring(1);

        cleanedUsers.push(obj);
      }

      const response = await createUsers(cleanedUsers);

      if (!response) {
        this.dialog = true;
        return;
      }

      this.$store.commit(CLOSE_CONFIRMATION_DIALOG);
      this.resultDialog = true;
      this.users = [
        {
          firstName: '',
          lastName: '',
          email: '',
          company: '',
          phoneNumber: '',
        },
      ];
      this.result = response;
      this.$refs.form.reset();
    },

    submitForm() {
      const valid = this.$refs.form.validate();

      if (!valid) return;

      this.dialog = false;

      this.$store.commit(SET_CONFIRMATION_DIALOG, {
        visible: true,
        message: '',
        subMessage:
          'Once submitted, all user entries will be processed, and their applications will be automatically approved, granting them access to attend the Decode event. Users will receive their credentials via email to facilitate their participation.',
        onSubmit: this.createUsers,
        onCancel: () => {
          this.dialog = true;
        },
      });
    },
  },
};
</script>

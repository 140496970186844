export default [
  {
    text: 'Name',
    value: 'name',
    align: 'start',
  },
  {
    text: 'Account Created',
    value: 'dateCreated',
  },
  {
    text: 'Email Address',
    value: 'email',
  },
  {
    text: 'Company',
    value: 'company',
  },
  {
    text: 'Role',
    value: 'role',
  },
  {
    text: 'Note',
    value: 'lastNote',
    sortable: false,
  },
  {
    text: 'Status',
    value: 'status',
    sortable: false,
  },
  {
    text: 'Actions',
    value: 'action',
    sortable: false,
  },
];

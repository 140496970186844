<template>
  <div>
    <v-chip-group column>
      <v-chip class="ma-2" :color="isLastYearAttendee ? 'primary' : 'success'" small label outlined>
        <v-icon small left> {{ isLastYearAttendee ? 'mdi-page-previous' : 'mdi-new-box' }} </v-icon>
        {{ isLastYearAttendee ? 'Previous Attendee' : 'New Attendee' }}
      </v-chip>

      <div v-if="isRejected">
        <v-chip class="ma-2" color="black" label text-color="white" small>
          <v-icon small left> mdi-cancel </v-icon>
          Blocked
        </v-chip>
      </div>
      <div v-else-if="applicationStatus === 'Declined'">
        <v-chip class="ma-2" color="error" label text-color="white" small>
          <v-icon small left> mdi-account-cancel </v-icon>
          Declined
        </v-chip>
      </div>

      <div v-else-if="applicationStatus === 'Pending'">
        <v-chip class="ma-2" color="warning" label text-color="white" small>
          <v-icon small left> mdi-account-clock </v-icon>
          Pending
        </v-chip>

        <!-- <v-chip v-if="isAttendingAmSession || isAttendingAmSession" class="ma-2" color="deep-purple" small label text-color="white">
          <v-icon small left> mdi-book-check </v-icon>
          {{ attendingIdentifier(isAttendingAmSession, isAttendingPmSession, isAttendingPlenary) }}
        </v-chip> -->
      </div>

      <div v-else-if="applicationStatus === 'Approved'">
        <v-chip class="ma-2" color="success" label text-color="white" small>
          <v-icon small left> mdi-book-check </v-icon>
          Approved
        </v-chip>
      </div>

      <div v-else-if="applicationStatus === null">
        <v-chip class="ma-2" color="black" label text-color="white" small>
          <v-icon small left> mdi-note-remove</v-icon>
          No Application
        </v-chip>
      </div>

      <v-chip-group class="ma-2" column>
        <v-chip v-if="applicationStatus === 'Approved' && attendanceConfirmation === 'Yes'" small color="success" outlined label>
          <v-icon small left> mdi-email-check </v-icon>
          RSVP - Yes
        </v-chip>

        <v-chip v-else-if="applicationStatus === 'Approved' && attendanceConfirmation === 'No'" small color="error" outlined label>
          <v-icon small left> mdi-email-remove </v-icon>
          RSVP - No
        </v-chip>

        <v-chip v-else-if="applicationStatus === 'Approved'" small color="primary" outlined label>
          <v-icon small left> mdi-email-alert </v-icon>
          RSVP - Pending
        </v-chip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-if="isHolRegistrant" small color="teal darken-4" label text-color="white" v-bind="attrs" v-on="on">
              <v-icon small left> mdi-desktop-classic</v-icon>
              HOL
            </v-chip>
          </template>
          <span>Hands On Lab Registrant</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-if="isGigRegistrant" small color="pink darken-4" label text-color="white" v-bind="attrs" v-on="on">
              <v-icon small left> mdi-face-woman </v-icon>
              GIG
            </v-chip>
          </template>
          <span>Get It Girls Registrant</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-if="isCtfRegistrant" small color="blue darken-4" label text-color="white" v-bind="attrs" v-on="on">
              <v-icon small left> mdi-flag </v-icon>
              CTF
            </v-chip>
          </template>
          <span>Capture the Flag Registrant</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-if="isVicOneRegistrant" small color="red darken-4" label text-color="white" v-bind="attrs" v-on="on">
              <v-icon small left> mdi-car-wireless </v-icon>
              VicOne
            </v-chip>
          </template>
          <span>VicOne Registrant</span>
        </v-tooltip>
      </v-chip-group>

      <!-- <div v-else-if="isAttending">
        <v-chip class="ma-2" color="deep-purple" label text-color="white" small>
          <v-icon small left> mdi-book-check </v-icon>
          Approved
          {{ attendingIdentifier(isAttendingAmSession, isAttendingPmSession, isAttendingPlenary) }}
        </v-chip>
      </div> -->

      <!-- <div v-if="isFinalConfirmed && isAttending">
        <v-chip class="ma-2" color="green" label text-color="white">
          <v-icon left> mdi-send-check </v-icon>
          Attendance Confirmed
        </v-chip>
      </div> -->
    </v-chip-group>
  </div>
</template>

<script>
export default {
  name: 'StatusItem',
  props: {
    emailConfirmed: {
      type: Boolean,
      required: true,
    },
    isLastYearAttendee: {
      type: Boolean,
      required: true,
    },
    isRejected: {
      type: Boolean,
      required: true,
    },
    isConfirmed: {
      type: Boolean,
      required: true,
    },
    isPending: {
      type: Boolean,
      required: true,
    },
    isAttending: {
      type: Boolean,
      required: true,
    },
    isAttendingAmSession: {
      type: Boolean,
      required: true,
    },
    isAttendingPmSession: {
      type: Boolean,
      required: true,
    },
    isAttendingPlenary: {
      type: Boolean,
      required: true,
    },
    isFinalConfirmed: {
      type: Boolean,
      required: true,
    },
    isHolRegistrant: {
      type: Boolean,
      required: true,
    },
    isGigRegistrant: {
      type: Boolean,
      required: true,
    },
    isCtfRegistrant: {
      type: Boolean,
      required: true,
    },
    isVicOneRegistrant: {
      type: Boolean,
      required: true,
    },
    profileVerificationStatus: {
      type: String,
      required: false,
      default: null,
    },
    attendanceConfirmation: {
      type: String,
      required: false,
      default: null,
    },
    applicationStatus: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    attendingIdentifier(isAttendingAmSession, isAttendingPmSession, isAttendingPlenary) {
      if (isAttendingAmSession && isAttendingPmSession) return 'Attending AM and PM sessions';
      if (isAttendingAmSession) return 'Attending AM session';
      if (isAttendingPmSession) return 'Attending PM session';
      if (isAttendingPlenary) return 'Attending Plenary';
      return 'Attending';
    },
  },
};
</script>

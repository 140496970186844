export default {
  Email: 'email',
  FirstName: 'firstName',
  LastName: 'lastName',
  PhoneNumber: 'phoneNumber',
  IsAttendingAMSession: 'isAttendingAMSession',
  IsAttendingPMSession: 'isAttendingPMSession',
  IsAttendingPlenary: 'isAttendingPlenary',
  isFinalConfirmed: 'isFinalConfirmed',
  IsHolRegistrant: 'isHolRegistrant',
  IsGigRegistrant: 'isGigRegistrant',
  Status: {
    callback: (item) => {
      if (item.isRejected) return 'Rejected';
      if (item.isPending && !item.isConfirmed) return 'Pending';
      if (item.isConfirmed && !item.isAttending) return 'Confirmed';
      if (item.isAttending && item.isConfirmed) return 'Attending';
      return '';
    },
  },
  DateRegistered: {
    callback: (item) => {
      try {
        const parsedDate = new Date(item.dateCreated);
        const options = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          timeZone: 'UTC',
        };
        return new Intl.DateTimeFormat('en-US', options).format(parsedDate);
      } catch {
        return '';
      }
    },
  },
  Company: 'company',
  Industry: 'industry',
  Role: 'role',
  Function: 'function',
  RoleFunction: 'roleFunction',
};

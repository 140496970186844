<template>
  <div>
    <v-dialog v-model="dialog" transition="dialog-bottom-transition" scrollable max-width="600" @click:outside="onClose">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title> User Profile</v-toolbar-title>

          <template v-slot:extension>
            <v-tabs v-model="tab" align-with-title>
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab>Info</v-tab>
              <v-tab>Notes</v-tab>
              <v-tab>Logs</v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="tab" class="full-height-tab">
          <v-tab-item>
            <v-card v-if="user" height="900" class="overflow-auto">
              <v-card-text>
                <v-container class="mt-7 black--text">
                  <p class="mb-5 text-subtitle-1 font-weight-medium">Basic Information</p>

                  <v-row>
                    <v-col cols="12" lg="5">
                      <p class="text-overline mb-0 grey--text text--darken-1">Name</p>
                    </v-col>
                    <v-col class="my-auto">
                      <p class="text-body-1 mb-0">{{ user.firstName }} {{ user.lastName }}</p>
                    </v-col>
                  </v-row>

                  <v-divider class="mb-4"></v-divider>

                  <v-row>
                    <v-col cols="12" lg="5">
                      <p class="text-overline mb-0 grey--text text--darken-1">Date Registered</p>
                    </v-col>
                    <v-col class="my-auto">
                      <p class="text-body-1 mb-0">{{ user.dateCreated | readableDate }}</p>
                    </v-col>
                  </v-row>

                  <v-divider class="mb-4"></v-divider>

                  <v-row>
                    <v-col cols="12" lg="5">
                      <p class="text-overline mb-0 grey--text text--darken-1">Status</p>
                    </v-col>
                    <v-col class="my-auto">
                      <status-item
                        :email-confirmed="user.emailConfirmed"
                        :is-last-year-attendee="user.isLastYearAttendee"
                        :is-rejected="user.isRejected"
                        :is-confirmed="user.isConfirmed"
                        :is-pending="user.isPending"
                        :is-attending="user.isAttending"
                        :profile-verification-status="user.profileVerificationStatus"
                        :application-status="user.applicationStatus"
                        :is-attending-am-session="user.isAttendingAMSession"
                        :is-attending-pm-session="user.isAttendingPMSession"
                        :is-attending-plenary="user.isAttendingPlenary"
                        :is-final-confirmed="user.isFinalConfirmed"
                        :is-hol-registrant="user.isHolRegistrant"
                        :is-gig-registrant="user.isGigRegistrant"
                        :is-ctf-registrant="user.isCtfRegistrant"
                        :attendance-confirmation="user.attendanceConfirmation"
                      />
                    </v-col>
                  </v-row>

                  <div v-if="user.applicationStatus === 'Declined'">
                    <v-divider class="mb-4"></v-divider>
                  </div>

                  <v-row v-if="user.applicationStatus === 'Declined'">
                    <v-col cols="12" lg="5">
                      <p class="text-overline mb-0 grey--text text--darken-1">Rejection reason</p>
                    </v-col>
                    <v-col class="my-auto">
                      <p class="text-body-1 mb-0">{{ user.applicationRemarks }}</p>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container class="mt-5 black--text">
                  <p class="mb-5 text-subtitle-1 font-weight-medium">Profile Verification</p>

                  <v-row>
                    <v-col cols="12" lg="5">
                      <p class="text-overline mb-0 grey--text text--darken-1">Work Email</p>
                    </v-col>
                    <v-col class="my-auto">
                      <p v-if="user.workEmail" class="text-body-1 mb-0">
                        {{ user.workEmail }}
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" :loading="verifyLoading" icon color="primary" v-on="on" @click="verifyDomain(user.workEmail)">
                              <v-icon color="primary">mdi-magnify</v-icon>
                            </v-btn>
                          </template>
                          <span>Verify email's domain</span>
                        </v-tooltip>
                      </p>
                      <p v-else class="text-body-1 mb-0">N/A</p>
                    </v-col>

                    <v-col cols="12">
                      <v-slide-y-transition>
                        <v-alert v-show="verifyResult != null" class="mb-5" border="top" colored-border type="info" elevation="2">
                          <p>
                            Please note that the provided method checks if the domain has MX records to handle incoming emails, but it does not directly verify the existence of a specific email
                            address. DNS-based email address validation has limitations and may produce false positives or false negatives.
                          </p>
                          <p v-if="verifyResult" class="font-weight-bold">The domain seems valid. <v-icon class="mx-2" color="success">mdi-email-check</v-icon></p>
                          <p v-else class="font-weight-bold">Can't find the domain<v-icon class="mx-2" color="error">mdi-email-off</v-icon></p>
                        </v-alert>
                      </v-slide-y-transition>
                    </v-col>
                  </v-row>

                  <v-divider class="mb-4"></v-divider>

                  <v-row>
                    <v-col cols="12" lg="5">
                      <p class="text-overline mb-0 grey--text text--darken-1">LinkedIn</p>
                    </v-col>
                    <v-col class="my-auto">
                      <p v-if="user.linkedIn" class="text-body-1 mb-0">
                        <a :href="user.linkedIn" target="_blank">{{ user.linkedIn }}</a>
                      </p>
                      <p v-else class="text-body-1 mb-0">N/A</p>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container class="mt-5 black--text">
                  <p class="mb-5 text-subtitle-1 font-weight-medium">Contact info</p>

                  <v-row>
                    <v-col cols="12" lg="5">
                      <p class="text-overline mb-0 grey--text text--darken-1">Email address</p>
                    </v-col>
                    <v-col class="my-auto">
                      <p class="text-body-1 mb-0">{{ user.email }}</p>
                    </v-col>
                  </v-row>

                  <v-divider class="mb-4"></v-divider>

                  <v-row>
                    <v-col cols="12" lg="5">
                      <p class="text-overline mb-0 grey--text text--darken-1">Mobile Number</p>
                    </v-col>
                    <v-col class="my-auto">
                      <p class="text-body-1 mb-0">{{ user.phoneNumber }}</p>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container class="mt-5 black--text">
                  <p class="mb-5 text-subtitle-1 font-weight-medium">Professional info</p>

                  <v-row>
                    <v-col cols="12" lg="5">
                      <p class="text-overline mb-0 grey--text text--darken-1">Company</p>
                    </v-col>
                    <v-col class="my-auto">
                      <p class="text-body-1 mb-0">{{ user.company }}</p>
                    </v-col>
                  </v-row>

                  <v-divider class="mb-4"></v-divider>

                  <v-row>
                    <v-col cols="12" lg="5">
                      <p class="text-overline mb-0 grey--text text--darken-1">Role</p>
                    </v-col>
                    <v-col class="my-auto">
                      <p class="text-body-1 mb-0">{{ user.role }}</p>
                    </v-col>
                  </v-row>

                  <v-divider class="mb-4"></v-divider>

                  <v-row>
                    <v-col cols="12" lg="5">
                      <p class="text-overline mb-0 grey--text text--darken-1">Industry</p>
                    </v-col>
                    <v-col class="my-auto">
                      <p class="text-body-1 mb-0">{{ user.industry }}</p>
                    </v-col>
                  </v-row>

                  <v-divider class="mb-4"></v-divider>

                  <v-row>
                    <v-col cols="12" lg="5">
                      <p class="text-overline mb-0 grey--text text--darken-1">User Function</p>
                    </v-col>
                    <v-col class="my-auto">
                      <p class="text-body-1 mb-0">{{ user.function }}</p>
                    </v-col>
                  </v-row>

                  <v-divider class="mb-4"></v-divider>

                  <v-row>
                    <v-col cols="12" lg="5">
                      <p class="text-overline mb-0 grey--text text--darken-1">Role function</p>
                    </v-col>
                    <v-col class="my-auto">
                      <p class="text-body-1 mb-0">{{ user.roleFunction }}</p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card height="900" class="overflow-auto">
              <v-progress-linear v-show="loading" indeterminate color="primary"></v-progress-linear>
              <v-card-text>
                <div v-for="(note, index) in notes" :key="note.id">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ note.content }}</v-list-item-title>
                      <v-list-item-subtitle>{{ note.admin }}</v-list-item-subtitle>
                      <v-list-item-subtitle class="text-caption"> {{ note.dateCreated | readableDate }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-show="index !== notes.length"></v-divider>
                </div>

                <p v-show="notes.length === 0 && !loading" class="text-center text-h5 mt-5">No notes are entered</p>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card height="1000" class="overflow-y-auto mb-5">
              <v-progress-linear v-show="loading" indeterminate color="primary"></v-progress-linear>
              <v-card-text>
                <v-list>
                  <div v-for="(log, index) in logs" :key="index" class="h-100">
                    <v-divider v-show="index !== 0 && index !== logs.length" inset></v-divider>

                    <v-list-item>
                      <v-list-item-avatar :color="user.userId === log.user ? 'blue' : 'red'">
                        <v-icon dark> {{ user.userId === log.user ? 'mdi-account' : 'mdi-clipboard-account' }} </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>{{ log.message }}</v-list-item-title>
                        <v-list-item-subtitle class="font-weight-medium mt-2">Route: {{ log.route }}</v-list-item-subtitle>
                        <v-list-item-subtitle class="font-weight-medium mb-1">
                          Origin: {{ log.origin }} <span class="font-weight-bold">({{ log.code }})</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-show="user.userId !== log.user && log.email" class="font-weight-medium">
                          <v-chip small color="red" outlined>{{ log.email }} </v-chip>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text-caption mt-2"> {{ log.dateCreated | readableDate }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list>

                <p v-show="notes.length === 0 && !loading" class="text-center text-h5 mt-5">No logs captured</p>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        <v-card-actions class="justify-end">
          <v-btn text @click="onClose">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { format } from 'date-fns';
import StatusItem from '@/components/Registrants/StatusItem';
import { getNotesByUserId } from '@/services/modules/NoteService';
import { verifyDomain, getLogsByUserId } from '@/services/modules/RegistrantService';

export default {
  name: 'UserDialog',
  components: {
    StatusItem,
  },

  filters: {
    readableDate: (value) => {
      if (!value) return '';
      return format(new Date(value), 'MMMM d, y (h:mm a)');
    },
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tab: null,
      notes: [],
      logs: [],
      loading: true,
      verifyLoading: false,
      verifyResult: null,
    };
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.verifyResult = null;
        this.notes = [];
        this.logs = [];
        this.getExtraInfo();
      }
    },
  },
  methods: {
    async getExtraInfo() {
      this.loading = true;
      const getNotesResponse = await getNotesByUserId(this.user.userId);
      if (getNotesResponse) this.notes = getNotesResponse;
      const getLogsResponse = await getLogsByUserId(this.user.userId);
      if (getLogsResponse) this.logs = getLogsResponse;
      this.loading = false;
    },
    async verifyDomain(email) {
      this.verifyLoading = true;
      this.verifyResult = await verifyDomain(email);
      this.verifyLoading = false;
    },
  },
};
</script>

<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" v-on="on" @click="dialog = true">
          <v-list-item-title class="black--text"> <v-icon color="black" class="mr-2">mdi-cancel</v-icon><span>Block</span> </v-list-item-title>
        </v-list-item>
      </template>
      <span>Block user account</span>
    </v-tooltip>

    <v-dialog v-model="dialog" :persistent="isLoading" width="500">
      <v-card :loading="isLoading">
        <v-card-title>Block user's account?</v-card-title>
        <v-card-text>
          <p>Blocking this user will result in their authentication being restricted within our system.</p>
          <v-form ref="reasonForRejectionForm" v-model="rejectionFormIsValid">
            <v-textarea v-model="otherReason" :rules="otherReasonForRejectionRules" outlined name="input-7-4" label="Reason"></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn outlined :disabled="isLoading" @click="dialog = false">Cancel</v-btn>
          <v-btn color="primary" :loading="isLoading" @click="submitForm()">Block</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { SET_RESPONSE_DIALOG } from '@/shared/mutations/type';

export default {
  name: 'BlockDialog',
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      rejectionFormIsValid: true,
      otherReasonForRejectionRules: [(v) => !!v || 'This field is required'],
      reasonForRejection: 'Others',
      otherReason: '',
    };
  },
  methods: {
    ...mapActions(['blockUserAccountAction']),

    async submitForm() {
      if (!this.$refs.reasonForRejectionForm.validate()) return;

      this.isLoading = true;

      const response = await this.blockUserAccountAction({
        id: this.userId,
        reason: this.reasonForRejection,
        remarks: this.otherReason,
      });

      if (response) {
        this.$store.commit(`${SET_RESPONSE_DIALOG}`, {
          visible: true,
          isSuccess: true,
          message: 'Success',
          subMessage: 'The user has been successfully blocked.',
          errors: [],
        });

        this.dialog = false;
      } else this.dialog = true;

      this.isLoading = false;
    },
  },
};
</script>

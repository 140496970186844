<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" v-on="on" @click="dialog = true">
          <v-list-item-title class="primary--text"> <v-icon color="primary" class="mr-2">mdi-note-plus-outline</v-icon><span>Note</span></v-list-item-title>
        </v-list-item>
      </template>
      <span>Add notes to this user</span>
    </v-tooltip>

    <v-dialog v-model="dialog" :persistent="isLoading" width="500">
      <v-card :loading="isLoading">
        <v-card-title>Add Notes</v-card-title>
        <v-card-text>
          <v-form ref="noteFormIsValid" v-model="noteFormIsValid">
            <v-textarea v-model="content" :rules="contentRules" outlined name="input-7-4" label="Content"></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn outlined :disabled="isLoading" @click="dialog = false">Cancel</v-btn>
          <v-btn color="primary" :loading="isLoading" @click="submitForm()">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { SET_RESPONSE_DIALOG } from '@/shared/mutations/type';

export default {
  name: 'NoteDialog',
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      noteFormIsValid: true,
      contentRules: [(v) => !!v || 'This field is required', (v) => v.length < 501 || 'Maximum of 500 characters'],
      content: '',
    };
  },
  methods: {
    ...mapActions(['addNoteAction']),

    async submitForm() {
      if (!this.$refs.noteFormIsValid.validate()) return;
      this.isLoading = true;
      const response = await this.addNoteAction({ userId: this.userId, content: this.content });

      if (response) {
        this.$store.commit(`${SET_RESPONSE_DIALOG}`, {
          visible: true,
          isSuccess: true,
          message: 'Success',
          subMessage: null,
          errors: [],
        });

        this.content = '';
        this.dialog = false;
      } else this.dialog = true;

      this.isLoading = false;
    },
  },
};
</script>
